<template>
  <div class="headerContainer">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 md-12 nameContainer" @click="navigateToShop">
          <img
              :src="window.Routes.getImage(shopProfile.logo, 'preview')"
              class="profileImage"
          />
          <div class="font-size-lg">
            {{ shopProfile.name }}
          </div>
        </div>

        <div class="col-lg-7 md-12 rightSection">
          <!-- drop-down categories -->
          <div
              class="row  rightContainer"
              v-bind:class="{
              rightContainerCategories: $route.name === 'FeaturedProducts'
            }"
          >
            <div class="col-lg-5  ">
              <div class="dropdownContainer">
                <div
                    class="dropdownBox"
                    @click="actionDropDown"
                    v-bind:class="{ activeDropdownBox: activeDropDown }"
                >
                  {{
                    selectedCategory !== null
                        ? selectedCategory.name
                        : $t("shops.all_categories")
                  }}
                  <div
                      class="dropdownIcon"
                      v-bind:class="{ activeIcon: activeDropDown }"
                  ></div>
                </div>

                <div
                    class="categoriseContainer"
                    v-bind:class="{ activeDrop: activeDropDown }"
                >
                  <div
                      v-for="category in shopCategories"
                      :key="category.id"
                      @click="selectCategory(category)"
                      class="categoryBox"
                  >
                    {{ category.name }}
                  </div>
                </div>
              </div>
            </div>

            <!--            search product-->

            <div
                class="searchContainer  col-lg-7"
                v-if="$route && $route.name !== 'FeaturedProducts'"
            >
              <form @submit.prevent="submitForm" class="formContainer">
                <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('shops.products.search')"
                    v-model="search_query"
                />
                <div class="searchButton" @click="submitForm"></div>
                <!-- /.searchButton -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" id="ShopProducts">
    <div class="header row justify-content-between">
      <div class="col-auto">
        <div class="font-size-xl font-weight-bold">{{ currentRouteName }}</div>
        <div class="font-size-sm color-secondary">
          {{ totalProduct }} {{ $t("shops.results_found") }}
        </div>
      </div>
    </div>
    <div v-if="loading">
      <div class="row mb-5">
        <div
            class="col-6 col-lg-4 col-xl-3 skeleton mb-3"
            v-for="index in 12"
            :key="index"
        >
          <div class="ratio ratio-4_3 bg-gray"></div>
          <div class="line skeleton_loader"></div>
          <div class="line skeleton_loader w-50"></div>
          <div class="line skeleton_loader dark w-30 thick"></div>
        </div>
      </div>
    </div>
    <div class="row mb-5" v-if="!loading && noProduct.length < 1">
      <product-card
          class="col-6 col-lg-4 col-xl-3"
          v-for="product in products"
          :product="product"
          :key="product.id"
      />
    </div>
    <div v-if="noProduct.length > 0">
      {{ noProduct }}
    </div>

    <loading :loading="productsLoader"/>
  </div
  >
</template>

<script>
import Loading from "@/components/Loading.vue";

import ProductCard from "@/components/ProductCard";
import {mapActions, mapGetters} from "vuex";
import prepareGuestAddressParams from "@/includes/prepareGuestAdderssParams";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "FeaturedProducts",
  components: {ProductCard, Loading},
  setup(){
    const route = useRoute();
    const {t} = useI18n() // use as global scope
    useMeta({
      title: route.query.title ?? t("shops.products.page_title"),
    })
  },
  data() {
    return {
      shopCategories: [],
      productsLoader: false,
      loading: false,
      totalProduct: 0,
      page: 1,
      products: [],
      currentRouteName: "",
      totalPage: 0,
      shopProfile: {},
      selectedCategory: null,
      activeDropDown: false,
      noProduct: "",
      pageCategory: 1,
      search_query: ""
    };
  },
  methods: {
    ...mapActions(["getShopProfile", "setErrors"]),

    async navigateToShop() {
      await this.$router.push({
        path: `/shops/` + this.$route.params.id
      });
    },

    submitForm: async function () {
      this.loading = true;

      try {
        if (
            this.selectedCategory &&
            this.selectedCategory.name !== "All Categories"
        ) {
          await this.searchCategoriesProduct(this.search_query);
        } else {
          await this.searchShopProducts(this.search_query);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async selectCategory(category) {
      this.activeDropDown = false;
      try {
        this.loading = true;
        if (category.name === "All Categories") {
          await this.getShopProduct(1);
        } else {
          await this.getCategoriesProducts(category, 1);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.selectedCategory = category;

        this.loading = false;
      }
    },

    actionDropDown() {
      this.activeDropDown = !this.activeDropDown;
    },

    getCategoriesProducts(category, page) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("shop_categories_products", {
          shop_id: this.$route.params.id,
          category_id: category.id,
          paginate: 12,
          page: page
        });
        window.axios.get(url).then(
            response => {
              if (response.data.data && Array.isArray(response.data.data)) {
                this.noProduct = "";
                this.products = response.data.data;
                this.totalProduct = response.data.meta.total;
                this.pageCategory = response.data.meta.current_page;
                this.totalPage = response.data.meta.last_page;
                resolve(response.data);
              } else {
                this.noProduct = response.data;
                resolve(response.data);
                this.totalProduct = 0;
              }
            },
            error => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
        );
      });
    },

    searchCategoriesProduct(term) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("search_categories_products", {
          shop_id: this.$route.params.id,
          category_id: this.selectedCategory.id,
          paginate: 12,
          page: this.pageCategory,
          term: term
        });
        window.axios.get(url, {
          params: prepareGuestAddressParams(),
        }).then(
            response => {
              if (response.data.data && Array.isArray(response.data.data)) {
                this.noProduct = "";
                this.products = response.data.data;
                this.totalProduct = response.data.meta.total;
                this.pageCategory = response.data.meta.current_page;
                this.totalPage = response.data.meta.last_page;
                resolve(response.data);
              } else {
                this.noProduct = response.data;
                resolve(response.data);
                this.totalProduct = 0;
              }
            },
            error => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
        );
      });
    },

    async getFeaturedProducts(page) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("shop_featured_products", {
          shop_id: this.$route.params.id,
          paginate: 12,
          page: page
        });
        window.axios.get(url, {
          params: prepareGuestAddressParams(),
        }).then(
            response => {
              this.products = response.data.data;
              this.totalProduct = response.data.meta.total;
              this.page = response.data.meta.current_page;
              this.totalPage = response.data.meta.last_page;
              resolve(response.data);
            },
            error => {
              this.setErrors([error.response.data.errors]);
              reject(error.response.data.errors);
            }
        );
      });
    },

    searchShopProducts(term) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("search_shop_products", {
          shop_id: this.$route.params.id,
          paginate: 12,
          page: this.page,
          term: term
        });
        window.axios.get(url, {
          params: prepareGuestAddressParams(),
        }).then(
            response => {
              this.products = response.data.data;
              this.totalProduct = response.data.meta.total;
              this.page = response.data.meta.current_page;
              this.totalPage = response.data.meta.last_page;
              resolve(response.data);
            },
            error => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
        );
      });
    },

    async getAllProducts(page) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("shop_all_products", {
          shop_id: this.$route.params.id,
          paginate: 12,
          page: page
        });
        window.axios.get(url).then(
            response => {
              this.products = response.data.data;
              this.totalProduct = response.data.meta.total;
              this.page = response.data.meta.current_page;
              this.totalPage = response.data.meta.last_page;
              resolve(response.data);
            },
            error => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
        );
      });
    },

    async getShopProduct(page) {
      this.loading = true;
      try {
        this.selectedCategory = null;
        if (this.$route.name === "AllProducts") {
          await this.getAllProducts(page);
        } else {
          await this.getFeaturedProducts(page);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async loadMore() {
      if (this.page < this.totalPage || this.pageCategory < this.totalPage) {
        if (window.innerHeight + window.scrollY >= document.body.scrollHeight) {
          this.productsLoader = true;
          if (
              this.selectedCategory &&
              this.selectedCategory.name !== "All Categories"
          ) {
            await this.getCategoriesProducts(
                this.selectedCategory,
                this.pageCategory + 1
            );
            this.productsLoader = false;
          } else {
            await this.getShopProduct(this.page + 1);
            this.productsLoader = false;
          }
        }
      }
    }
  },

  created() {
    this.getShopProfile({shopId: this.$route.params.id});

    if (this.$route.name === "AllProducts") {
      this.currentRouteName = "All Products";
    } else {
      this.currentRouteName = "Featured Products";
    }
    this.getShopProduct(1);
  },
  mounted() {
    window.onscroll = () => {
      this.loadMore();
    };
    this.shopProfile = this.shop;
  },
  computed: {
    ...mapGetters(["shop"])
  },
  watch: {
    shop() {
      this.shopProfile = this.shop;
      this.shopCategories = [{name: "All Categories"}].concat(
          this.shop.product_categories
      );
    },
    search_query() {
      if (this.search_query.length === 0) {
        if (this.selectedCategory) {
          this.selectCategory(this.selectedCategory);
        } else {
          this.getAllProducts(1);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.headerContainer {
  background-color: map-get($colors, "lightGray");
  margin-bottom: 40px;

  .profileImage {
    object-fit: cover;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-right: 10px;
    border: 1px solid map-get($colors, "bordering-gray");
  }

  .nameContainer {
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .rightSection {
    align-items: center;

    .rightContainerCategories {
      justify-content: flex-end;
    }

    .rightContainer {
      height: 100%;
      align-items: center;

      .dropdownContainer {
        position: relative;

        .dropdownBox {
          display: flex;
          flex-direction: row;
          cursor: pointer;
          padding: 0 15px;
          height: 48px;
          width: 100%;
          border: 1px solid map-get($colors, "gray");
          border-radius: 6px;
          background-color: white;
          align-items: center;
          justify-content: space-between;

          .dropdownIcon {
            width: 20px;
            height: 20px;
            background-image: url(~@/assets/arrow_down.svg);
            background-repeat: no-repeat;
            background-position: center;
          }

          .activeIcon {
            transform: rotate(-180deg);
          }
        }

        .activeDropdownBox {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        .categoriseContainer {
          overflow: hidden;
          position: absolute;
          width: 100%;
          max-height: 0;
          background-color: white;
          left: 0;
          top: 100%;
          z-index: 20;
          border-bottom-right-radius: 6px;
          border-bottom-left-radius: 6px;
          padding: 0 15px;

          .categoryBox {
            border-bottom: 1px solid map-get($colors, "gray");
            padding: 10px 0;
          }
        }

        .activeDrop {
          overflow: scroll;
          max-height: 200px;
          cursor: pointer;
          border: 1px solid map-get($colors, "gray");

          .categoryBox {
          }
        }
      }

      .searchContainer {
        .formContainer {
          position: relative;

          .form-control {
            width: 100%;
            border: 1px solid map-get($colors, "gray");
            border-radius: 6px;
            height: 48px;
          }

          .searchButton {
            background-color: white;
            position: absolute;
            width: 30px;
            height: 30px;
            top: 50%;
            right: 15px;
            transform: translateY(-50%);
            background-image: url(~@/assets/nav/search.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px 20px;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

#ShopProducts {
  min-height: 100%;

  .header {
    margin-bottom: 20px;
  }
}

@include media-breakpoint-down(lg) {
  .dropdownContainer {
    margin-bottom: 10px;
  }
  .searchContainer {
    margin-bottom: 10px;
  }
}
</style>
